import React, { useEffect, useState } from 'react';

import 'assets/styles/app.scss';

import Card from '@moonshineragency/ui/src/components/Card/Card';
import Form, {
  Field,
  RadioGroup,
  CheckboxGroup,
  FormNavigation,
} from '@moonshineragency/ui/src/components/Forms/Forms';

import Container from 'components/Container/Container';
import Steps, { Step } from '@moonshineragency/ui/src/components/Steps/Steps';
import Layout from 'components/Layout/Layout';

const Example2Page = () => {
  const [width, setWidth] = useState(0);

  const resizeHandler = () => {
    setWidth(document.body.getBoundingClientRect().width);
  };

  useEffect(() => {
    setWidth(document.body.getBoundingClientRect().width);
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const verticalSteps = width >= 980;
  const verticalClassNames = 'p-3 w-20 mr-3 mt-4 h-50';
  const stepsClassNames = `${verticalSteps ? verticalClassNames : ''}`;

  return (
    <Layout title="Stromanschluss ändern">
      <Container className="py-3">
        <div className="d-lg-flex">
          <div className={`sticky-top ${stepsClassNames}`}>
            <Steps
              className="py-2"
              orientation={(verticalSteps && 'vertical') || 'horizontal'}
              active="location"
            >
              <Step id="customer" title="Kunde" icon="user" />
              <Step id="location" title="Standort" icon="home" />
              <Step id="connection" title="Anschluss" icon="units" />
              <Step id="electrician" title="Elektriker" icon="wrench" />
              <Step id="check" title="Prüfen" icon="check" />
            </Steps>
          </div>
          <Form className="mt-4 flex-basis-100">
            <Card className="mb-4">
              <div className="mb-3">
                <CheckboxGroup
                  type="pill"
                  items={[
                    {
                      name: 'sonne',
                      label: 'Sonne',
                    },
                    {
                      name: 'wasser',
                      label: 'Wasser',
                    },
                    {
                      name: 'Wind',
                      label: 'Wind',
                    },
                    {
                      name: 'Batterie',
                      label: 'Batterie',
                    },
                    {
                      name: 'Klärgas',
                      label: 'Klärgas',
                    },
                    {
                      name: 'Deponiegas',
                      label: 'Deponiegas',
                    },
                    {
                      name: 'Rest-/Abfallstoffe',
                      label: 'Rest-/Abfallstoffe',
                    },
                    {
                      name: 'Sonstige',
                      label: 'Sonstige',
                    },
                  ]}
                  name="usageType"
                />
              </div>
              <div className="mb-3">
                <RadioGroup
                  labelClassName="font-weight-normal"
                  label="Art der Einspeisung"
                  items={[
                    {
                      name: 'small',
                      label: 'Volleinspeisung (100% Einspeisung)',
                    },
                    {
                      name: 'large',
                      label:
                        'Überschusseinspeisung (teilweiser Eigenverbrauch)',
                    },
                  ]}
                  name="usage"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="kundennummer"
                  as="textarea"
                  placeholder="optional"
                  label="Was möchten Sie uns noch mitteilen?"
                />
              </div>
              <div className="mb-3 mt-4">
                <Field name="vorname" type="text" label="Vorname" />
              </div>
              <div className="mb-3">
                <Field name="nachname" type="text" label="Nachname" />
              </div>
              <div className="mb-3">
                <Field name="adresse" type="text" label="Adresse" />
              </div>
              <div>
                <Field
                  name="telefon"
                  type="text"
                  label="Handy- oder Festnetznummer"
                />
              </div>
            </Card>
            <FormNavigation back="/consumer/step1" />
          </Form>
        </div>
      </Container>
    </Layout>
  );
};

export default Example2Page;
